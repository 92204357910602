.ant-btn-primary {
  background: #244bf1 !important;
  border-radius: 2px;

  &:hover {
    background: #2256c1 !important;
  }

  &:active {
    background-color: #0e3484 !important;
  }
}

.ant-btn-default {
  background: #f2f3f5;
  border-radius: 4px;
  border: 0;
  color: rgba(0, 0, 0, 0.4) !important;

  &:hover {
    background: #e8eaef !important;
  }

  &:active {
    background: #dbdce2 !important;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;

  &:hover {
    box-shadow: 0 0 0 1px #2256c1 !important;
  }

  &:active {
    box-shadow: 0 0 0 1px #2256c1 !important;
  }
}

.ant-btn-dashed {
  border-style: solid;
}

.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #244bf1;
  border-color: #244bf1;
}

.ant-input:placeholder-shown {
  border-color: transparent;
}

.formInput,
.ant-select-selector {
  height: 40px;
  background: #e8eaef !important;
  border-radius: 4px !important;
  border: none !important;

  &:hover {
    box-shadow: 0 0 0 1px #2256c1 !important;
  }

  &:active {
    box-shadow: 0 0 0 1px #2256c1 !important;
  }
}

.ant-input {
  background: #e8eaef !important;
}

.formInput {
  height: 32px;
}

.switchInput {
  width: 340px !important;
}

//ant form
.ant-form-item-row {
  flex: 1;
}

.ant-form-item {
  margin-right: 0 !important;
  margin-bottom: 16px !important;
}

.ant-form-item {
  display: flex;
  flex-wrap: wrap;
}

.ant-picker {
  width: 100%;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: 80px !important;
}

.ant-form-item .ant-form-item-control-input-content {
  position: relative;
}

//ant table
.ant-table-cell::before {
  width: 0 !important;
}

.ant-table-content {
  border: 1px solid #e8e8e8;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #2256c1 !important;
}

.ant-carousel .slick-dots li button {
  width: 4px !important;
  height: 4px !important;
  background: rgba(123, 123, 123, 0.48) !important;
  border-radius: 50% !important;
}

.ant-carousel .slick-dots li {
  width: 4px !important;
}

.ant-input-affix-wrapper {
  position: relative;

  .ant-input-data-count {
    position: absolute;
    bottom: 0;
    right: 12px;
  }
}

.slick-dots-bottom {
  bottom: 5px !important;
}

.ant-form-item .ant-form-item-label>label::after {
  content: '' !important;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper>textarea.ant-input {
  min-height: 100px;
}

.ant-select .ant-select-clear {
  background: #e8eaef !important;
}

.ant-picker .ant-picker-clear {
  background: #e8eaef !important;
}

.ant-form-item {
  .ant-input-affix-wrapper .ant-input-clear-icon {
    margin-top: -5px !important;
  }
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 10px !important;
}

.popover {
  .ant-popover-inner {
    padding: 6px 16px !important;
  }
}

.ant-select-selector {
  height: 100% !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

// ant switch
.ant-switch {
  width: 47px;
  height: 24px;
}

.ant-switch.ant-switch-checked {
  background-color: #50b142 !important;
}

.css-dev-only-do-not-override-1n9gz8k.ant-pagination .ant-pagination-item-active a,
.css-dev-only-do-not-override-1n9gz8k.ant-pagination .ant-pagination-item-active:hover a {
  color: rgba(255, 255, 255, 0.9);
}

.css-dev-only-do-not-override-1n9gz8k.ant-pagination .ant-pagination-item-active {
  border: 0 !important;
}

.namespace>.ant-popover-content>.ant-popover-inner {
  box-shadow: 0px 2px 4px 0px rgba(32, 74, 156, 0.2);
  padding: 0 !important;
  border-radius: 4px !important;
}

.app-version {
  position: fixed;
  bottom: 8px;
  left: 50vw;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 400;
}

.css-dev-only-do-not-override-1n9gz8k.ant-space {
  gap: 4px !important;
}

.popoverBox>.ant-popover-content>.ant-popover-inner {
  width: 120px !important;
  height: 80px !important;
  border-radius: 4px !important;
  padding: 4px 0 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}