.cnNotify {
  background-color: rgb(0, 0, 0, .45);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .cnNotify_wrapper {
    width: 360px;
    height: 200px;
    border-radius: 8px;
    background-color: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cnNotify_wrapper_text {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
    }

    .cnNotify_wrapper_btn_box {
      display: flex;

      .cnNotify_wrapper_btn {
        margin-left: auto;
        color: rgba(255, 255, 255, 0.9);
        background-color: #244bf1;
        height: 32px;
        min-width: 88px;
        padding: 0 16px;
        font-size: 14px;
        border-radius: 4px;
        user-select: none;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover {
          background-color: #3a5df2;
        }

        &:active {
          background-color: #1f40cd;
        }

        &:disabled {
          background-color: #92a5f8;
        }
      }
    }
  }
}